import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Redirect } from "@reach/router"

import * as actions from "../store/actions"

const Logout = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(actions.logout())
    } else {
      if (typeof window !== `undefined`) window.location.replace(`/`)
    }
  }, [isLoggedIn])

  return <div></div>
}

export default Logout
